import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, withDirectives as _withDirectives, vShow as _vShow, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9be67da8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spin"
}
const _hoisted_2 = { style: {"white-space":"pre-line"} }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "question" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "remark-question" }
const _hoisted_7 = { class: "question" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "remark-question" }
const _hoisted_10 = { class: "question" }
const _hoisted_11 = {
  class: "question",
  style: {"margin-bottom":"0"}
}
const _hoisted_12 = ["src", "onClick"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "question" }
const _hoisted_15 = ["value"]
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { class: "question" }
const _hoisted_18 = ["value"]
const _hoisted_19 = { key: 3 }
const _hoisted_20 = { class: "ion-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_image_slides = _resolveComponent("image-slides")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_signature_pad = _resolveComponent("signature-pad")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.loading || $setup.loadingUser ? '...' : $setup.notification.title), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    "default-href": $setup.parentPath ? $setup.parentPath : '/notifications'
                  }, null, 8, ["default-href"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          ($setup.loading || $setup.loadingUser)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner)
              ]))
            : (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                fixed: ""
              }, {
                default: _withCtx(() => [
                  ($setup.notification)
                    ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, { size: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, { class: "rounded-border" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_col, { size: "12" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_item, {
                                            lines: "full",
                                            "router-link": {
                    name: 'ProjectDetailPage',
                    params: { id: $setup.notification.projectId }
                  },
                                            button: "",
                                            detail: ""
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString($setup.notification.projectTitle), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }, 8, ["router-link"]),
                                          _createVNode(_component_ion_card_header, { class: "ion-text-center ion-margin-bottom" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_card_title, { color: "primary" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString($setup.notification.title), 1)
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_ion_card_subtitle, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString($setup.formatDate($setup.notification.scheduledToSendAt)), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_card_content, null, {
                                            default: _withCtx(() => [
                                              _createElementVNode("p", _hoisted_2, _toDisplayString($setup.notification.content), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      ($setup.notification.photos.length > 0)
                                        ? (_openBlock(), _createBlock(_component_ion_col, {
                                            key: 0,
                                            size: "12"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_image_slides, {
                                                class: "ion-margin-bottom",
                                                photos: $setup.notification.photos
                                              }, null, 8, ["photos"])
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      ($setup.notification.survey)
                                        ? (_openBlock(), _createBlock(_component_ion_col, {
                                            key: 1,
                                            size: "12"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_item, { lines: "none" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_chip, { slot: "start" }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_icon, { icon: $setup.clipboardOutline }, null, 8, ["icon"]),
                                                      _createVNode(_component_ion_label, null, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString($setup.notification.survey), 1)
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  }),
                                                  ($setup.notification.userNotSubmittedSurvey)
                                                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                                                        key: 0,
                                                        color: "danger"
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString($setup.t('notSubmitted')) + " ", 1),
                                                          _createVNode(_component_ion_icon, { icon: $setup.close }, null, 8, ["icon"])
                                                        ]),
                                                        _: 1
                                                      }))
                                                    : (_openBlock(), _createBlock(_component_ion_badge, {
                                                        key: 1,
                                                        color: "success"
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString($setup.t('submitted')) + " ", 1),
                                                          _createVNode(_component_ion_icon, { icon: $setup.checkmark }, null, 8, ["icon"])
                                                        ]),
                                                        _: 1
                                                      }))
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              ($setup.notification.survey)
                                ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_col, { size: "12" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("form", {
                                            onSubmit: _cache[14] || (_cache[14] = _withModifiers(
//@ts-ignore
(...args) => ($setup.submitSurvey && $setup.submitSurvey(...args)), ["prevent"]))
                                          }, [
                                            _createVNode(_component_ion_list, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_list_header, { color: "primary" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("回條 / Reply Sheet")
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_ion_item, { class: "input-box" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: $setup.person,
                                                      slot: "start"
                                                    }, null, 8, ["icon"]),
                                                    _createVNode(_component_ion_label, { position: "stacked" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString($setup.t('FeedbackPage.nickname')), 1)
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_ion_input, {
                                                      placeholder: $setup.t('FeedbackPage.enterNickname'),
                                                      modelValue: $setup.response.name,
                                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.response.name) = $event)),
                                                      required: ""
                                                    }, null, 8, ["placeholder", "modelValue"])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_ion_item, { class: "input-box" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: $setup.call,
                                                      slot: "start"
                                                    }, null, 8, ["icon"]),
                                                    _createVNode(_component_ion_label, { position: "stacked" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString($setup.t('UserProfilePage.phone')), 1)
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_ion_input, {
                                                      placeholder: $setup.t('FeedbackPage.enterPhone'),
                                                      inputmode: "numeric",
                                                      modelValue: $setup.response.phone,
                                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.response.phone) = $event)),
                                                      required: ""
                                                    }, null, 8, ["placeholder", "modelValue"])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_ion_item, { class: "input-box" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: $setup.mail,
                                                      slot: "start"
                                                    }, null, 8, ["icon"]),
                                                    _createVNode(_component_ion_label, { position: "stacked" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString($setup.t('FeedbackPage.email')), 1)
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_ion_input, {
                                                      placeholder: $setup.t('FeedbackPage.enterEmail'),
                                                      type: "email",
                                                      modelValue: $setup.response.email,
                                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.response.email) = $event))
                                                    }, null, 8, ["placeholder", "modelValue"])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_ion_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_col, {
                                                      class: "ion-no-padding",
                                                      size: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_ion_item, { class: "input-box" }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_ion_label, { position: "stacked" }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString($setup.t('floor')), 1)
                                                              ]),
                                                              _: 1
                                                            }),
                                                            _createVNode(_component_ion_input, {
                                                              modelValue: $setup.response.floor,
                                                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.response.floor) = $event)),
                                                              required: ""
                                                            }, null, 8, ["modelValue"])
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_ion_col, {
                                                      class: "ion-padding-start ion-no-padding",
                                                      size: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_ion_item, { class: "input-box" }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_ion_label, { position: "stacked" }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString($setup.t('unit')), 1)
                                                              ]),
                                                              _: 1
                                                            }),
                                                            _createVNode(_component_ion_input, {
                                                              modelValue: $setup.response.unit,
                                                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.response.unit) = $event)),
                                                              required: ""
                                                            }, null, 8, ["modelValue"])
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            (['開工問卷', '完工問卷'].includes($setup.notification.survey))
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                                  _createElementVNode("p", _hoisted_4, "1. " + _toDisplayString($setup.locale == 'zh' ? $setup.surveyData.q1 : $setup.surveyData.q1En), 1),
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(($setup.surveyData.q1Options || '').split(' , '), (option) => {
                                                    return (_openBlock(), _createBlock(_component_ion_item, {
                                                      lines: "none",
                                                      class: "answer-option-item",
                                                      key: option
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _withDirectives(_createElementVNode("input", {
                                                          type: "radio",
                                                          slot: "start",
                                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.response.q1Ans) = $event)),
                                                          name: "surveyQ1",
                                                          value: option,
                                                          required: ""
                                                        }, null, 8, _hoisted_5), [
                                                          [_vModelRadio, $setup.response.q1Ans]
                                                        ]),
                                                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                                          default: _withCtx(() => [
                                                            _createElementVNode("p", null, _toDisplayString(option), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024))
                                                  }), 128)),
                                                  _withDirectives(_createElementVNode("div", null, [
                                                    _createElementVNode("p", _hoisted_6, _toDisplayString($setup.locale == 'zh' ? $setup.surveyData.q1Remark : $setup.surveyData.q1RemarkEn), 1),
                                                    _createVNode(_component_ion_item, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_ion_input, {
                                                          modelValue: $setup.response.q1Remark,
                                                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.response.q1Remark) = $event))
                                                        }, null, 8, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ], 512), [
                                                    [_vShow, $setup.response.q1Ans == $setup.surveyData.q1RemarkShowVal]
                                                  ]),
                                                  _createElementVNode("p", _hoisted_7, "2. " + _toDisplayString($setup.locale == 'zh' ? $setup.surveyData.q2 : $setup.surveyData.q2En), 1),
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(($setup.surveyData.q2Options || '').split(' , '), (option) => {
                                                    return (_openBlock(), _createBlock(_component_ion_item, {
                                                      lines: "none",
                                                      class: "answer-option-item",
                                                      key: option
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _withDirectives(_createElementVNode("input", {
                                                          type: "radio",
                                                          slot: "start",
                                                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.response.q2Ans) = $event)),
                                                          name: "surveyQ2",
                                                          value: option,
                                                          required: ""
                                                        }, null, 8, _hoisted_8), [
                                                          [_vModelRadio, $setup.response.q2Ans]
                                                        ]),
                                                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                                          default: _withCtx(() => [
                                                            _createElementVNode("p", null, _toDisplayString(option), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024))
                                                  }), 128)),
                                                  _withDirectives(_createElementVNode("div", null, [
                                                    _createElementVNode("p", _hoisted_9, _toDisplayString($setup.locale == 'zh' ? $setup.surveyData.q2Remark : $setup.surveyData.q2RemarkEn), 1),
                                                    _createVNode(_component_ion_item, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_ion_input, {
                                                          modelValue: $setup.response.q2Remark,
                                                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.response.q2Remark) = $event))
                                                        }, null, 8, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ], 512), [
                                                    [_vShow, $setup.response.q2Ans == $setup.surveyData.q2RemarkShowVal]
                                                  ]),
                                                  _createElementVNode("p", _hoisted_10, "3. " + _toDisplayString($setup.locale == 'zh' ? $setup.surveyData.q3 : $setup.surveyData.q3En), 1),
                                                  _createVNode(_component_ion_item, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_textarea, {
                                                        modelValue: $setup.response.q3Ans,
                                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.response.q3Ans) = $event))
                                                      }, null, 8, ["modelValue"])
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createElementVNode("p", _hoisted_11, "4. " + _toDisplayString($setup.t('photoOptional')), 1),
                                                  _createVNode(_component_ion_list, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_row, null, {
                                                        default: _withCtx(() => [
                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.photos, (photo) => {
                                                            return (_openBlock(), _createBlock(_component_ion_col, {
                                                              size: "4",
                                                              "size-lg": "3",
                                                              "size-xl": "2",
                                                              key: photo
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createElementVNode("img", {
                                                                  src: photo.base64Data,
                                                                  onClick: ($event: any) => ($setup.showActionSheet(photo))
                                                                }, null, 8, _hoisted_12)
                                                              ]),
                                                              _: 2
                                                            }, 1024))
                                                          }), 128)),
                                                          _createVNode(_component_ion_col, {
                                                            size: "4",
                                                            "size-lg": "3",
                                                            "size-xl": "2"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_ion_card, { class: "photo-card ion-align-items-center ion-justify-content-center ion-text-center" }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_ion_fab_button, {
                                                                    class: "take-photo-fab-btn",
                                                                    onClick: _cache[10] || (_cache[10] = ($event: any) => ($setup.takePhoto()))
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_ion_icon, { icon: $setup.camera }, null, 8, ["icon"])
                                                                    ]),
                                                                    _: 1
                                                                  })
                                                                ]),
                                                                _: 1
                                                              })
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  })
                                                ]))
                                              : ($setup.notification.survey == '外牆分色問卷')
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                                    _createElementVNode("p", _hoisted_14, _toDisplayString($setup.locale == 'zh' ? $setup.surveyData.q1 : $setup.surveyData.q1En), 1),
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.externalWallDesigns, (design) => {
                                                      return (_openBlock(), _createBlock(_component_ion_item, {
                                                        lines: "none",
                                                        class: "answer-option-item",
                                                        key: design.id
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_ion_thumbnail, {
                                                            slot: "end",
                                                            onClick: ($event: any) => ($setup.openImageModal(design.photoLink, design.description))
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_ion_img, {
                                                                src: design.photoLink
                                                              }, null, 8, ["src"])
                                                            ]),
                                                            _: 2
                                                          }, 1032, ["onClick"]),
                                                          _withDirectives(_createElementVNode("input", {
                                                            type: "radio",
                                                            slot: "start",
                                                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.response.q1Ans) = $event)),
                                                            name: "surveyQ1",
                                                            value: design.id,
                                                            required: ""
                                                          }, null, 8, _hoisted_15), [
                                                            [_vModelRadio, $setup.response.q1Ans]
                                                          ]),
                                                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                                            default: _withCtx(() => [
                                                              _createElementVNode("p", null, _toDisplayString(design.description), 1)
                                                            ]),
                                                            _: 2
                                                          }, 1024)
                                                        ]),
                                                        _: 2
                                                      }, 1024))
                                                    }), 128))
                                                  ]))
                                                : ($setup.notification.survey == '大堂設計方案問卷')
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                                      _createElementVNode("p", _hoisted_17, _toDisplayString($setup.locale == 'zh' ? $setup.surveyData.q1 : $setup.surveyData.q1En), 1),
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.lobbyDesigns, (design) => {
                                                        return (_openBlock(), _createBlock(_component_ion_item, {
                                                          lines: "none",
                                                          class: "answer-option-item",
                                                          key: design.id
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_ion_thumbnail, {
                                                              slot: "end",
                                                              onClick: ($event: any) => ($setup.openImageModal(design.photoLink, design.description))
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_ion_img, {
                                                                  src: design.photoLink
                                                                }, null, 8, ["src"])
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["onClick"]),
                                                            _withDirectives(_createElementVNode("input", {
                                                              type: "radio",
                                                              slot: "start",
                                                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.response.q1Ans) = $event)),
                                                              name: "surveyQ1",
                                                              value: design.id,
                                                              required: ""
                                                            }, null, 8, _hoisted_18), [
                                                              [_vModelRadio, $setup.response.q1Ans]
                                                            ]),
                                                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                                              default: _withCtx(() => [
                                                                _createElementVNode("p", null, _toDisplayString(design.description), 1)
                                                              ]),
                                                              _: 2
                                                            }, 1024)
                                                          ]),
                                                          _: 2
                                                        }, 1024))
                                                      }), 128))
                                                    ]))
                                                  : _createCommentVNode("", true),
                                            ($setup.showFrame)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                                  _createElementVNode("div", _hoisted_20, [
                                                    _createVNode(_component_ion_text, { color: "primary" }, {
                                                      default: _withCtx(() => [
                                                        _createElementVNode("b", null, _toDisplayString($setup.t('signInBox')), 1)
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _createVNode(_component_signature_pad, {
                                                    modelValue: $setup.response.signatureDataURL,
                                                    onInput: $setup.onInput,
                                                    width: '100%',
                                                    height: 100,
                                                    customStyle: _ctx.customStyle,
                                                    saveType: _ctx.saveType,
                                                    saveOutput: _ctx.saveOutput,
                                                    ref: "signaturePad"
                                                  }, null, 8, ["modelValue", "onInput", "customStyle", "saveType", "saveOutput"]),
                                                  _createVNode(_component_ion_button, {
                                                    size: "small",
                                                    color: "light",
                                                    onClick: _cache[13] || (_cache[13] = ($event: any) => ($setup.clearSignature()))
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_icon, {
                                                        slot: "start",
                                                        icon: $setup.refresh
                                                      }, null, 8, ["icon"]),
                                                      _createTextVNode(" " + _toDisplayString($setup.t('resetSignature')), 1)
                                                    ]),
                                                    _: 1
                                                  })
                                                ]))
                                              : _createCommentVNode("", true),
                                            _createElementVNode("div", null, [
                                              _createVNode(_component_ion_button, {
                                                color: "success",
                                                type: "submit",
                                                shape: "round",
                                                class: "submit-btn",
                                                expand: "block"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString($setup.t('submit')), 1)
                                                ]),
                                                _: 1
                                              })
                                            ])
                                          ], 32)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}