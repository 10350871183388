
// Vue reactivity
import { computed, reactive, Ref, ref, toRefs, watch } from 'vue';

// icons
import { person, call, mail, refresh, clipboardOutline, checkmark, close,
          camera, thumbsUpOutline, thumbsDownOutline, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonButtons,
        IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonIcon, IonBackButton,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonThumbnail,
        IonList, IonListHeader, IonInput, IonTextarea, IonText, IonChip, IonBadge, IonImg,
        IonFabButton, loadingController, modalController } from '@ionic/vue';
import ImageSlides from "@/components/ImageSlides.vue";
import { ImageTypesEnum, SaveOutputsEnum } from '@/utils';
import { ISignaturePad } from '@/types';
import SignaturePad from 'vue3-signature-pad';

// API services
import CommonService from '@/services/CommonService';

import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { usePhotoGallery } from '@/composables/usePhotoGallery';

export default {
  name: 'NotificationDetailPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonButtons,
                IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonIcon, IonBackButton,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonThumbnail,
                IonList, IonListHeader, IonInput, IonTextarea, IonText, IonChip, IonBadge, IonImg,
                IonFabButton, SignaturePad, ImageSlides },
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();
    const route = useRoute();
    const currId = route.params.id; // post ID
    const { photos, takePhoto, showActionSheet } = usePhotoGallery();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingNotifications);
    const notification = computed(() => store.getters.getNotificationById(currId));
    const userLocation = computed(() => store.getters.getUserLocationByProjectId(notification.value.projectId));
    const lobbyDesigns = computed(() => store.getters.getLobbyDesignsByProjectId(notification.value.projectId));
    const externalWallDesigns = computed(() => store.getters.getExternalWallDesignsByProjectId(notification.value.projectId));
    const user = computed(() => store.state.user);
    const loadingUser = computed(() => store.state.loadingUser);
    const surveyData = computed(() => store.getters.getSurveyByKey(notification.value.survey));
    const response = reactive({
      name: user.value.firstName || "",
      phone: user.value.phone || "",
      email: user.value.email || "",
      floor: "",
      unit: "",
      role: "",
      q1Ans: "",
      q1TextValue: "",
      q1Remark: "",
      q2Ans: "",
      q2Remark: "",
      q3Ans: "",
      signatureDataURL: null,
    });
    const setResponseValues = (userLocation: any) => {
      // prefill the input fields in the survey
      const { floor, unit, role } = userLocation;
      const { survey } = notification.value;
      response.floor = floor;
      response.unit = unit;
      response.role = role;
      if (survey) { // survey notifications
        const splitQ1Ans = (userLocation[`${survey} q1Ans`] || "").split(" | ");
        const splitQ2Ans = (userLocation[`${survey} q2Ans`] || "").split(" | ");
        if (!response.q1Ans) response.q1Ans = splitQ1Ans[0];
        if (!response.q1Remark) response.q1Remark = splitQ1Ans[1] || "";
        if (!response.q2Ans) response.q2Ans = splitQ2Ans[0];
        if (!response.q2Remark) response.q2Remark = splitQ2Ans[1] || "";
        if (!response.q3Ans) response.q3Ans = userLocation[`${survey} q3Ans`] || "";
      }
    }
    if (userLocation.value) setResponseValues(userLocation.value);
    const showFrame = ref(false);
    const state = reactive({
      customStyle: { border: "#fda619 2px solid" },
      saveType: ImageTypesEnum.PNG,
      saveOutput: SaveOutputsEnum.DATA_URL,
    });
    const signaturePad: Ref<ISignaturePad | null> = ref(null);
    setTimeout(() => {
      showFrame.value = true; // prevent component not init properly
    }, 500);

    watch(user, (currUser) => { // triggered only when direct access to this page
      response.name = currUser.firstName;
      response.phone = currUser.phone;
      response.email = currUser.email;
    });
    watch(userLocation, (currUserLocation) => { // triggered only when direct access to this page
      setResponseValues(currUserLocation);
      showFrame.value = false;
      setTimeout(() => {
        showFrame.value = true; // prevent component not init properly
      }, 500);
    });

    // methods or filters
    const parentPath = route.params.parentPath;
    const { formatDate, presentToast, openImageModal, } = utils();

    const submitSurvey = async () => {
      const loading = await loadingController.create({});
      await loading.present();
      const design = [...lobbyDesigns.value, ...externalWallDesigns.value].find(d => d.id == response.q1Ans)
      if (design) response.q1TextValue = design.description;
      await CommonService.createNewSurveyResponse(response, photos.value, notification.value);
      store.dispatch('getUserData');
      loading.dismiss();
      presentToast(t('surveySubmitted'), 5000, 'top');
    }

    // Signature pad
    function getSignaturePad(): ISignaturePad {
      if (!signaturePad.value) throw new Error("No signature pad ref could be found");
      return signaturePad.value;
    }
    const clearSignature = () => { getSignaturePad().clearSignature() }
    const onInput = (value: any) => { response.signatureDataURL = !value ? null : value }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      person, call, mail, refresh, clipboardOutline, checkmark, close, camera,

      // variables
      locale, currId, parentPath, loading, loadingUser, notification,
      surveyData, response,
      lobbyDesigns, externalWallDesigns,
      ...toRefs(state), signaturePad, showFrame,
      photos,

      // methods
      t, formatDate, submitSurvey, openImageModal,
      clearSignature, onInput,
      takePhoto, showActionSheet,
    }
  },
}
